import { translate } from "@locales";
// import { NumberFormatValues } from "react-number-format";

export default function useFormikHelpers(formik: any) {
  const displayError = (key: string): string | undefined => {
    // @ts-ignore
    return formik.touched[key] && formik.errors[key] ? formik.errors[key] : undefined;
  }

  const handleTouched = (key: string) => {
    formik.setTouched({ ...formik.touched, [key as any]: true })
  }

  return {
    operations: {
      displayError,
      handleTouched,
    }
  }
} 