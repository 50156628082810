import {
  sendAuthEvent,
  useSocialAuth,
} from "@models/interactions/use-social-auth";
import SignUpSection from "@components/organisms/sign-up-section";
import ErrorMessage from "@components/molecules/error-message";
import LoadingModal from "@components/molecules/loading-modal";
import SignUpForm from "@components/organisms/sign-up-form";
import SignUpABTest from "@components/organisms/sign-up-ab-test";
import LoginForm from "@components/organisms/login-form";
import BaseModal from "@components/atoms/base-modal";
import CookiesUniversal from "cookie-universal";
import { useState } from "react";
import { UserAtom } from "@models/state";
import { sdk, sdkv1 } from "@models/api";
import { translate } from "@locales";
import { useAtom } from "jotai";
import {
  CreateUserParams,
  LoginResponse,
  UserInterface,
} from "@kanvas/client-js";
import {
  AUTH_STATUS,
  AuthModalAtom,
} from "@models/state/atoms/auth-modal.atom";
import { UserLoggedAtom } from "@models/state/atoms/user-logged.atom";
import { useRouter } from "next/router";
import useAmplitude from "@models/interactions/use-amplitude";

interface Props {}

interface sessionProps {}

const cookie = CookiesUniversal();

export function useAuthModal(props: sessionProps) {
  const { operations } = useAmplitude();
  const router = useRouter();
  const [modal, setModal] = useAtom(AuthModalAtom);
  const [logged, setLogged] = useAtom(UserLoggedAtom);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState<{ open: boolean; title: string }>({
    open: false,
    title: "",
  });

  const [user, setUser] = useAtom(UserAtom);

  const handler = useSocialAuth({
    user,
    onError: (title) => setOpen({ open: true, title: translate(title) }),
    setOpen,
  });

  const switchToLogin = () => setModal({ mode: AUTH_STATUS.LOGIN, open: true });
  const switchToSignUp = () =>
    setModal({ mode: AUTH_STATUS.SIGN_UP_SECTION, open: true });
  const switchToSignUpForm = () =>
    setModal({ mode: AUTH_STATUS.SIGN_UP_FORM, open: true });

  const handleClose = () => {
    switchToLogin();
  };

  const handleCloseError = () => {
    setOpen({ open: false, title: "" });
  };

  const handleLogin = (token: LoginResponse, user: UserInterface) => {
    cookie.setAll([
      {
        name: "token",
        value: token.token,
        opts: {
          path: "/",
          domain: window.location.hostname,
          sameSite: true,
        },
      },
      {
        name: "access_token",
        value: token.token,
        opts: {
          path: "/",
          domain: window.location.hostname,
          sameSite: true,
        },
      },
      {
        name: "refresh_token",
        value: token.refresh_token,
        opts: {
          path: "/",
          domain: window.location.hostname,
          sameSite: true,
        },
      },
    ]);

    operations.setUserId(user.id.toString());
    sdk.tokenProvider.setRefreshToken(token.refresh_token);
    sdk.tokenProvider.setToken(token.token);

    sendAuthEvent("email");
    setUser(user);
    setLogged(true);
    close();
  };

  const handleSubmit = async (email: string, password: string) => {
    try {
      setLoading(true);
      const token = await sdkv1.auth.login(email, password);
      const user = await sdkv1.users.getById(0);
      handleLogin(token, user);
    } catch (e) {
      setOpen({ open: true, title: translate("base.not-user-found") });
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (data: CreateUserParams) => {
    try {
      setLoading(true);
      const user = await sdkv1.users.create(data as CreateUserParams);
      const token = await sdkv1.auth.login(data.email, data.password);

      window.sessionStorage.setItem(
        "signup-register",
        JSON.stringify({
          unknown_user_id: operations.getUserId(),
          previous_path: router.asPath,
          registration_method: "email",
        }),
      );

      handleLogin(token, user);

      router.push("/onboarding");
    } catch (e: any) {
      console.log(e);
      setOpen({ open: true, title: e.errors.message });
    } finally {
      setLoading(false);
    }
  };

  const close = () => {
    setModal({
      open: false,
      mode: AUTH_STATUS.LOGIN,
    });

    setOpen({ open: false, title: "" });
  };

  return {
    model: {
      loading,
      open,
      modal,
    },
    operations: {
      ...handler.operations,
      switchToLogin,
      switchToSignUp,
      switchToSignUpForm,
      handleClose,
      handleSubmit,
      handleSignUp,
      handleCloseError,
      close,
    },
  };
}

export default function AuthModal(props: Props) {
  const { model, operations } = useAuthModal(props);

  return (
    <>
      <LoadingModal open={model.loading} />
      <BaseModal {...props} open={model.modal.open}>
        <ErrorMessage
          open={model.open.open}
          onClose={operations.handleCloseError}
          title={model.open.title}
        />
        {model.modal.mode === AUTH_STATUS.LOGIN && (
          <LoginForm
            onClose={operations.close}
            onSignUp={operations.switchToSignUp}
            onSubmit={operations.handleSubmit}
            onTwitter={operations.handleTwitter}
            onApple={operations.openApple}
            onFacebook={operations.handleFacebook}
          />
        )}
        {model.modal.mode === AUTH_STATUS.SIGN_UP_SECTION && (
          <SignUpSection
            onClose={operations.close}
            onTwitter={() => operations.handleTwitter(false)}
            onFacebook={() => operations.handleFacebook(false)}
            onApple={() => operations.openApple(false)}
            onSignIn={operations.switchToLogin}
            onSignUp={operations.switchToSignUpForm}
          />
        )}
        {model.modal.mode === AUTH_STATUS.SIGN_UP && (
          <SignUpABTest
            onClose={operations.close}
            onSignIn={operations.switchToLogin}
            onSignUp={operations.switchToSignUpForm}
          />
        )}
        {model.modal.mode === AUTH_STATUS.SIGN_UP_FORM && (
          <SignUpForm
            onClose={operations.close}
            onSignIn={operations.switchToLogin}
            onSignUp={operations.switchToSignUp}
            onSubmit={operations.handleSignUp}
          />
        )}
      </BaseModal>
    </>
  );
}
