import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { SocialAuthProps, useSocialAuth } from "..";

export default function Apple(props: SocialAuthProps) {
  const { model } = useSocialAuth(props);
  return (
    <button type="button" className="sign-in-button bg-white text-black" onClick={props.onClick}>
      <div className="divider">
        <Icon.Apple />
      </div>

      <span>{translate(`base.${model.type}.apple`)}</span>
    </button>
  );
}