import SocialAuthButton from "@components/molecules/social-auth-button";
import useFormikHelpers from "@models/interactions/use-formik-helper";
import AuthHeader from "@components/molecules/auth-header";
import Button from "@components/atoms/button";
import Form from "@components/molecules/form";
import { translate } from "@locales";
import { useFormik } from "formik";
import * as yup from "yup";
import Link from "next/link";

const emailRequired = translate("form.required.email");
const passwordRequired = translate("form.required.password");

const validationSchema = yup.object().shape({
  email: yup.string().email(emailRequired).required(emailRequired),
  password: yup.string().required(passwordRequired),
});

const DEFAULT_VALUES = {
  email: "",
  password: "",
};

interface Props {
  onSignUp?: VoidFunction;
  onSubmit?: (email: string, passworD: string) => void;
  onTwitter?: VoidFunction;
  onApple?: VoidFunction;
  onFacebook?: VoidFunction;
  onClose?: () => void;
}

export function useLoginForm(props: Props) {
  const formik = useFormik({
    initialValues: DEFAULT_VALUES,
    onSubmit: ({ email, password }) => props.onSubmit?.(email, password),
    validationSchema,
  });

  const { operations } = useFormikHelpers(formik);

  return {
    model: {
      formik,
    },
    operations: {
      ...operations,
    },
  };
}

export default function LoginForm(props: Props) {
  const { model, operations } = useLoginForm(props);
  const { values, isValid, dirty, handleBlur, handleChange, handleSubmit } =
    model.formik;

  return (
    <div className="flex h-[590px] max-h-[590px] w-[400px] flex-col justify-between rounded-3xl bg-memod-base sm:w-[500px]">
      <div className="flex flex-col gap-1">
        <AuthHeader
          onClose={props.onClose}
          title={translate("base.sign-in.base")}
        />

        <form
          onSubmit={handleSubmit}
          className="mb-2 flex w-full flex-col gap-3.5 px-[5rem] sm:px-[7.2188rem]"
        >
          <div className="flex flex-col gap-6">
            <Form.TextInput
              name="email"
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
              label={translate("base.your-email")}
              placeholder={translate("base.enter-your-email")}
              error={operations.displayError("email")}
            />

            <Form.PasswordInput
              name="password"
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              label={translate("base.your-password")}
              placeholder={translate("base.enter-your-password")}
              error={operations.displayError("password")}
            />
          </div>

          <Link
            href="/forgot-password"
            className="text-center text-sm font-semibold text-memod-green-base hover:underline"
          >
            {translate("base.forgot-password")}
          </Link>

          <Button.Pill
            type="submit"
            variant="primary"
            disabled={!(dirty && isValid)}
          >
            {translate("base.sign-in.base")}
          </Button.Pill>

          <SocialAuthButton.Facebook onClick={props.onFacebook} />
          <SocialAuthButton.Apple onClick={props.onApple} />
        </form>
      </div>

      <div className="flex h-14 w-full items-center justify-center overflow-hidden rounded-b-3xl bg-memod-black">
        <p className="text-xs text-memod-white-base">
          {translate("base.dont-have-account")}{" "}
          <span className="link-green" onClick={props.onSignUp}>
            {translate("base.sign-up.base")}
          </span>
        </p>
      </div>
    </div>
  );
}
