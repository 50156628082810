import Icon from "@components/atoms/icon";

interface Props {
  title: string;
  onClose?: VoidFunction;
}

export default function AuthHeader(props: Props) {
  return (
    <header className="p-8 flex flex-col">
      <Icon.Close className="text-white self-end cursor-pointer" onClick={props.onClose} />
      <h1 className="text-center text-white text-[2.625rem] leading-[2.875rem]">{props.title}</h1>
    </header>
  );
}