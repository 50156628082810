import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  size?: string | number;
}

export default function StarCluster({ size = 32, ...props }: Props) {
  return (
    <svg width={size} height={size} {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5933 5.09737C19.7328 4.72042 20.2659 4.72042 20.4054 5.09737L21.5628 8.22504C22.1705 9.86735 23.4653 11.1622 25.1076 11.7699L28.2353 12.9273C28.6123 13.0668 28.6123 13.5999 28.2353 13.7394L25.1076 14.8967C23.4653 15.5045 22.1705 16.7993 21.5628 18.4416L20.4054 21.5693C20.2659 21.9463 19.7328 21.9463 19.5933 21.5693L18.4359 18.4416C17.8282 16.7993 16.5334 15.5044 14.8911 14.8967L11.7634 13.7394C11.3864 13.5999 11.3864 13.0668 11.7634 12.9273L14.8911 11.7699C16.5334 11.1622 17.8282 9.86735 18.4359 8.22504L19.5933 5.09737Z" fill="currentColor" />
      <path d="M5.83692 3.10545C5.89272 2.95467 6.10598 2.95467 6.16177 3.10545L6.34693 3.60581C6.69775 4.55391 7.44527 5.30143 8.39337 5.65226L8.89374 5.83741C9.04452 5.89321 9.04452 6.10647 8.89373 6.16226L8.39337 6.34741C7.44527 6.69824 6.69775 7.44576 6.34692 8.39386L6.16177 8.89422C6.10598 9.045 5.89272 9.045 5.83692 8.89422L5.65177 8.39386C5.30094 7.44576 4.55343 6.69824 3.60533 6.34741L3.10496 6.16226C2.95418 6.10647 2.95418 5.89321 3.10496 5.83741L3.60533 5.65226C4.55343 5.30143 5.30094 4.55391 5.65177 3.60581L5.83692 3.10545Z" fill="currentColor" />
      <path d="M9.26841 18.5522C9.38 18.2506 9.80653 18.2506 9.91811 18.5522L10.5142 20.1631C10.9193 21.258 11.7826 22.1212 12.8774 22.5263L14.4883 23.1224C14.7899 23.234 14.7899 23.6605 14.4883 23.7721L12.8774 24.3682C11.7826 24.7733 10.9193 25.6366 10.5142 26.7315L9.91811 28.3423C9.80653 28.6439 9.38 28.6439 9.26841 28.3423L8.67234 26.7315C8.2672 25.6366 7.40395 24.7733 6.30908 24.3682L4.69821 23.7721C4.39664 23.6605 4.39665 23.234 4.69821 23.1224L6.30908 22.5263C7.40395 22.1212 8.2672 21.258 8.67234 20.1631L9.26841 18.5522Z" fill="currentColor" />
    </svg>
  );
}