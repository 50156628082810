import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { SocialAuthProps, useSocialAuth } from "..";

export default function Twitter(props: SocialAuthProps) {
  const { model } = useSocialAuth(props);

  return (
    <button type="button" className="sign-in-button bg-[#1ca0f2] text-white" onClick={props.onClick}>
      <div className="divider">
        <Icon.Twitter />
      </div>

      <span>{translate(`base.${model.type}.twitter`)}</span>
    </button>
  );
}