import { translate } from "@locales";

export default function AgrementTerms() {
  return (
    <span className="text-white text-xs text-center leading-[.875rem] mt-1">
      {translate('base.conditions-message')} <br />
      <a href="#" className="link-green">{translate('base.terms-condition')}</a> 
      {` ${translate('base.and')} `}
      <a href="#" className="link-green">{translate('base.privacy-policy')}</a>
    </span>
  );
}