import Apple from "./apple";
import Facebook from "./facebook";
import SignUp from "./sign-up";
import Twitter from "./twitter";

export interface SocialAuthProps {
  type?: 'sign-up' | 'sign-in';
  onClick?: VoidFunction;
}

export function useSocialAuth(props: SocialAuthProps) {
  const { type = 'sign-in' } = props;

  return {
    model: {
      type,
    },
  }
}

export default {
  Apple,
  Facebook,
  Twitter,
  SignUp,
}