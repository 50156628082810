import { FocusEvent, useEffect } from "react";
import Typography from "@components/atoms/typography";
import { InputProps } from "@components/molecules/form";
import classNames from "classnames";
import { useRef } from "react";


interface Props extends InputProps {
  fieldClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  maxLength?: number;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  bottonError?: boolean;
  showInputLen?: boolean;
}

export function useTextInput(props: Props) {
  const error = !!props.error;
  const inputRef = useRef(null);

  const len = props.value.toString().length;

  const inputClassName = classNames("form-input", props.inputClassName, { error });

  return {
    model: {
      inputClassName,
      inputRef,
      len
    },
  };
}

export default function TextInput(props: Props) {
  const { model } = useTextInput(props);
  
  return (
    <div className={classNames("form-field", props.fieldClassName)}>
      {props.label && <Typography.Label title={props.label} error={props.error} className={props.labelClassName} />}
      <div className="flex flex-row">
        <input
          ref={model.inputRef}
          type="text"
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          className={model.inputClassName}
          maxLength={props.maxLength}
          readOnly={props.readonly}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
        />
      </div>
      <div className="w-full flex justify-end">
        {(props.bottonError && props.error) ? (
          <span className="w-full text-memod-error-base text-xs">({props.error})</span>
        ): null}
        {props.showInputLen  && (
          <span className={classNames("w-1/4 text-xs  text-[#EBEBF54D] flex justify-end", {
            "!text-memod-error-base": model.len >= props.maxLength!
          })}>
            {`${model.len}/${props.maxLength}` }
          </span>
        )}
      </div>

    </div>
  );
}
