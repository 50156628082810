import AgrementTerms from "@components/molecules/agrement-terms";
import AuthHeader from "@components/molecules/auth-header";
import SocialAuthButton from "@components/molecules/social-auth-button";
import { translate } from "@locales";

interface Props {
  onSignIn?: () => void;
  onSignUp?: () => void;
  onClose?: () => void;
  onTwitter?: VoidFunction;
  onApple?: VoidFunction;
  onFacebook?: VoidFunction;
}

export function useSignUpSection() {
  const handleSignUp = () => {
    
  };

  return {
    operations: {
      handleSignUp,
    },
  };
}

export default function SignUpSection(props: Props) {
  return (
    <div className="bg-memod-base rounded-3xl w-full sm:w-[500px] h-[590px] max-h-[590px] flex flex-col justify-between">
      <div className="flex flex-col">
        <AuthHeader
          onClose={props.onClose}
          title={translate("base.sign-up.base")}
        />

        <div className="flex flex-col gap-3.5 w-full px-[5rem] sm:px-[7.2188rem] mb-2">
          <SocialAuthButton.Facebook type="sign-up" onClick={props.onFacebook} />
          <SocialAuthButton.Apple type="sign-up" onClick={props.onApple} />
          <SocialAuthButton.SignUp onClick={props.onSignUp} />
          <AgrementTerms />
        </div>
      </div>

      <div className="w-full bg-memod-black h-14 flex items-center justify-center rounded-b-3xl overflow-hidden">
        <p className="text-memod-white-base text-xs">
          {translate("base.already-account")}{" "}
          <span
            className="text-memod-green-base hover:underline cursor-pointer"
            onClick={props.onSignIn}
          >
            {translate("base.sign-in.base")}
          </span>
        </p>
      </div>
    </div>
  );
}
