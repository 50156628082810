import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  Icon?: JSX.Element;
  onclick?: () => void;
}

export function useRound() {
  return {};
}

export default function Round(props: Props): JSX.Element {
  const {} = useRound();

  return (
    <button
      className={classNames(
        "flex items-center justify-center rounded-full bg-memod-button p-2 outline-none md:p-2.5",
        props.className
      )}
      onClick={props.onclick}
    >
      {props.Icon ?? props.children}
    </button>
  );
}
