import Icon from "@components/atoms/icon";
import Typography from "@components/atoms/typography";
import { useMemo, useReducer } from "react";
import { InputProps } from "@components/molecules/form";
import classNames from "classnames";
import { useTextInput } from "../text-input";

interface Props extends InputProps {
  hideButton?: boolean;
  labelClassName?: string;
}

export function usePasswordInput(props: Props) {
  const [type, toggleType] = useReducer((value: boolean) => !value, false);
  const { model } = useTextInput(props);

  const currentType = useMemo(() => (type ? "text" : "password"), [type]);

  const eyeClassName = classNames("cursor-pointer p-0 form-input-eye", {
    "text-white": currentType === "text",
    "text-white/30": currentType === "password",
  });

  return {
    model: {
      ...model,
      currentType,
      eyeClassName,
    },
    operations: {
      toggleType,
    },
  };
}

export default function PasswordInput(props: Props) {
  const { model, operations } = usePasswordInput(props);

  return (
    <div className="form-field">
      {props.label  && <Typography.Label title={props.label} error={props.error} className={props.labelClassName} />}
      <div className="relative flex flex-row">
        <input
          name={props.name}
          value={props.value}
          onBlur={props.onBlur}
          type={model.currentType}
          onChange={props.onChange}
          placeholder={props.placeholder}
          className={classNames(model.inputClassName)}
        />

        {!props.hideButton && (
          <Icon.Eye
            size={25}
            className={model.eyeClassName}
            onClick={operations.toggleType}
          />
        )}
      </div>
    </div>
  );
}
