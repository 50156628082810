import { UserInterface } from "@kanvas/client-js";
import CookiesUniversal from "cookie-universal";
import { sdkv1 } from "@models/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import useAmplitude from "../use-amplitude";
import { useStore } from "jotai";
import {
  AUTH_STATUS,
  AuthModalAtom,
} from "@models/state/atoms/auth-modal.atom";
import { UserLoggedAtom } from "@models/state";

const AUTH_REDIRECT_KEY = "MEMOD::AUTH_REDIRECT";

interface Config {
  user: UserInterface | null;
  onError?: (title: string) => void;
  setOpen?: Dispatch<SetStateAction<{ open: boolean; title: string }>>;
}

const cookie = CookiesUniversal();

function getSingleWord(text: string, position: number) {
  return text.split(" ")[position];
}

function setSignUpStorage(method: string) {
  const { operations } = useAmplitude();

  window.sessionStorage.setItem(
    "signup-register",
    JSON.stringify({
      unknown_user_id: operations.getUserId(),
      previous_path: window.location.pathname,
      registration_method: method,
    }),
  );
}

export function sendAuthEvent(method: string) {
  const { operations } = useAmplitude();

  operations.send("Logged In", {
    logged_in_at: Date.now(),
    unknown_user_id: operations.getUserId(),
    login_method: method,
  });
}

async function socialSign(
  form: any,
  setSession: (state: boolean) => void,
  redirect?: Function,
) {
  localStorage.setItem("SOCIAL_REDIRECT", "/");
  try {
    // @ts-ignore
    const { data } = await sdkv1.http.http.post("/users/social", form, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    sendAuthEvent(form.provider);
    setSocialToken(data);
    setSession(true);
    redirect && redirect();
  } catch (err) {
    console.log(err);
  }
}

function setSocialToken(token: any) {
  cookie.set("refresh_token", token.refresh_token);
  cookie.set("access_token", token.token);
  cookie.set("token", token.token);
}

function generateFormData(data: any) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  return formData;
}

function processFacebookData(
  response: any,
  setSession: (state: boolean) => void,
) {
  const user = response;

  const signUpformData = {
    email: user?.email,
    firstname: getSingleWord(user?.name, 0),
    lastname: getSingleWord(user?.name, 1),
    social_id: user?.id,
    provider: "facebook",
  };

  const signInformData = {
    email: user?.email,
    social_id: user?.id,
    provider: "facebook",
  };

  const form = user?.email && user?.name
    ? generateFormData(signInformData)
    : generateFormData(signUpformData);
  return socialSign(form, setSession);
}

async function processAppleData(
  response: any,
  setSession: (state: boolean) => void,
  redirect?: Function,
) {
  const user = response?.user;

  const signUpformData = {
    email: user?.email,
    firstname: user?.name?.firstName,
    lastname: user?.name?.lastName,
    social_id: response?.id_token,
    provider: "apple",
  };

  const signInformData = {
    social_id: response?.id_token,
    provider: "apple",
  };

  const data = user
    ? generateFormData(signUpformData)
    : generateFormData(signInformData);
  return await socialSign(data, setSession, redirect);
}

export function useSocialAuth(config: Config) {
  const router = useRouter();
  const store = useStore();
  const [logged, setLogged] = useState(false);
  const [provider, setProvider] = useState("");

  useEffect(() => {
    if (logged) {
      store.set(UserLoggedAtom, true);
      store.set(AuthModalAtom, {
        mode: AUTH_STATUS.LOGIN,
        open: false,
      });
      
      if (provider === "facebook") {
        router.reload();
      }
    }
  }, [logged]);

  useEffect(() => {
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v6.0",
      });
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    script.async = true;
    script.onload = () => {
      // @ts-ignore
      window.AppleID.auth.init({
        clientId: process.env.NEXT_PUBLIC_APPLE_APP_ID as string,
        scope: "name email",
        redirectURI: process.env.NEXT_PUBLIC_APPLE_REDIRECT as string,
      });
    };
    document.body.appendChild(script);
  }, []);

  const handleTwitter = async (isLogin = true) => {
    if (!isLogin) {
      setSignUpStorage("twitter");
    }
    const callbackUrl = encodeURIComponent(location.href);
    const { url } = await sdkv1.getTweetrUrls.getTweetUrl(callbackUrl);
    localStorage.setItem(AUTH_REDIRECT_KEY, location.href);
    location.href = url;
  };

  const handleTwitterLogout = async () => {
    if (!config.user) return;
    await sdkv1.memodUsers.logoutTwitter(config.user.id);
    location.reload();
  };

  const openApple = (isLogin = true) => {
    if (!isLogin) {
      setSignUpStorage("apple");
    }
    localStorage.setItem(AUTH_REDIRECT_KEY, router.asPath);
    // @ts-ignore
    window.AppleID.auth.signIn({
      clientId: process.env.NEXT_PUBLIC_APPLE_APP_ID as string,
      scope: "name email",
      redirectURI: process.env.NEXT_PUBLIC_APPLE_REDIRECT as string,
      nonce: uuidv4(),
      state: router.asPath,
    });
  };

  const handleApple = async (response: any, redirect: Function) => {
    try {
      await processAppleData(response, setLogged, redirect);
    } catch (error) {
      config.onError?.("base.please-try-again");
    }
  };

  const handleFacebook = async (isLogin = true) => {
    if (!isLogin) {
      setSignUpStorage("facebook");
    }

    try {
      setProvider("facebook");
      //@ts-ignore
      FB.login(
        (response: any) => {
          if (response.authResponse) {
            // @ts-ignore
            FB.api("/me?fields=name,picture,email", (response) => {
              processFacebookData(response, setLogged);
            });
          } else {
            config.onError?.("base.error-social-media");
          }
        },
        { scope: "public_profile, email" },
      );
    } catch (error) {
      config.onError?.("base.please-try-again");
    }
  };

  return {
    operations: {
      openApple,
      handleApple,
      handleFacebook,
      handleTwitter,
      handleTwitterLogout,
    },
  };
}
