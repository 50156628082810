import { useState } from "react";
import { useRouter } from "next/router";
import { useAtomValue, useStore } from "jotai";
import { isIOS } from "react-device-detect";
import { translate } from "@locales";
import { UserLoggedAtom } from "@models/state";
import { AUTH_STATUS, AuthModalAtom } from "@models/state/atoms/auth-modal.atom";
import useAmplitude from "@models/interactions/use-amplitude";
import Button from "@components/atoms/button";
import StarCluster from "@components/atoms/svg/star-cluster";
import Icon from "@components/atoms/icon";

export default function AIBanner() {
  const session = useAtomValue(UserLoggedAtom);
  const route = useRouter();
  const store = useStore();
  const { operations: amplitude } = useAmplitude();
  const [open, setOpen] = useState(true);

  const action = () => {
    amplitude.send("AI Reader Banner Clicked", {
      clicked_at: Date.now(),
    });

    if (session) {
      route.push("/create/ai");
    } else {
      if (isIOS)
        return window.open(
          "https://apps.apple.com/us/app/memod-wisdom-inspires-focus/id1590241623",
          "_blank"
        );

      return store.set(AuthModalAtom, {
        mode: AUTH_STATUS.LOGIN,
        open: true,
      });
    }
  };

  return open ? (
    <div className="sticky top-0 z-[50] flex flex-row gap-2 items-center justify-between bg-memod-purple-500/20 px-4 py-3 lg:py-4 backdrop-blur-3xl">
      <div className="hidden lg:block" />
      <div className="flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-10">
        <div className="flex flex-col lg:flex-row lg:items-center gap-x-2 text-white">
          <StarCluster size={25} className="hidden lg:block text-memod-purple-500" />
          <span className="lg:text-xl lg:leading-8 font-semibold">
            {translate("ai-banner.title")}
          </span>

          <span className="text-sm lg:text-base font-gibson-light">
            {translate("ai-banner.sub")}
          </span>
        </div>

        <Button.Pill
          onClick={action}
          className="self-start !px-3.5 !py-2 text-sm leading-4 !bg-memod-purple-500 hover:!bg-memod-purple-500/70 h-auto"
        >
          {translate("ai-banner.button")}
        </Button.Pill>
      </div>

      <button
        className="w-5 h-5 lg:w-6 lg:h-6 self-start lg:self-auto p-1"
        onClick={() => setOpen(false)}
      >
        <Icon.Close className="w-full h-full text-base-white" />
      </button>
    </div>
  ) : (
    <></>
  );
}
