import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { SocialAuthProps, useSocialAuth } from "..";

export default function Facebook(props: SocialAuthProps) {
  const { model } = useSocialAuth(props);
  return (
    <button type="button" className="sign-in-button bg-[#4268b3] text-white" onClick={props.onClick}>
      <div className="divider">
        <Icon.Facebook />
      </div>

      <span>{translate(`base.${model.type}.facebook`)}</span>
    </button>
  );
}