import { PropsWithChildren } from "react";
import Icon from "@components/atoms/icon";
import classNames from "classnames";

interface Props extends PropsWithChildren {
  open?: boolean;
}

export default function LoadingModal(props: Props) {
  const container = classNames(
    "w-full h-full fixed top-0 left-0 bg-[rgba(0,0,0,.8)] z-50 items-center justify-center",
    {
      hidden: !props.open,
      flex: props.open,
    }
  );

  return (
    <div className={container}>
      <Icon.Loading />
    </div>
  );
}
