import type { ButtonHTMLAttributes, PropsWithChildren } from "react";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  Icon?: JSX.Element;
  onclick?: () => void;
}

export default function Icon(props: Props): JSX.Element {
  const { className, onclick, Icon } = props;

  return (
    <button className={className} onClick={onclick}>
      {Icon}
    </button>
  );
}
