import AgrementTerms from "@components/molecules/agrement-terms";
import AuthHeader from "@components/molecules/auth-header";
import Form from "@components/molecules/form";
import { translate } from "@locales";
import { useFormik } from "formik";
import * as yup from "yup";
import useFormikHelpers from "@models/interactions/use-formik-helper";
import Button from "@components/atoms/button";
import { CreateUserParams } from "@kanvas/client-js";

const emailRequired = translate("form.required.email");
const passwordRequired = translate("form.required.password");
const passwordConfirmationRequired = translate(
  "form.required.confirmation-password"
);

const validationSchema = yup.object().shape({
  email: yup.string().email(emailRequired).required(emailRequired),
  password: yup
    .string()
    .required(passwordRequired)
    .min(8, translate("form.password-eight-password")),
  verify_password: yup
    .string()
    .required(passwordConfirmationRequired)
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], translate("form.password-not-match")),
    }),
});

const DEFAULT_VALUES: CreateUserParams = {
  email: "",
  password: "",
  verify_password: "",
};

interface Props {
  onSignIn?: () => void;
  onSignUp?: () => void;
  onSubmit?: (data: CreateUserParams) => void;
  onClose?: () => void;
}

export function useSignUpForm(props: Props) {
  const formik = useFormik({
    initialValues: DEFAULT_VALUES,
    onSubmit: (values: CreateUserParams) => props.onSubmit?.(values),
    validationSchema,
  });

  const { operations } = useFormikHelpers(formik);

  return {
    model: {
      formik,
    },
    operations: {
      ...operations,
    },
  };
}

export default function SignUpForm(props: Props) {
  const { model, operations } = useSignUpForm(props);
  const { values, handleBlur, handleChange, handleSubmit } = model.formik;

  return (
    <div className="bg-memod-base rounded-3xl w-full sm:w-[500px] h-[590px] max-h-[590px] flex flex-col justify-between">
      <div className="flex flex-col">
        <AuthHeader
          onClose={props.onClose}
          title={translate("base.sign-up.base")}
        />

        <form
          className="flex flex-col gap-3.5 w-full px-[7.2188rem] mb-2"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-6">
            <Form.TextInput
              name="email"
              onBlur={handleBlur}
              value={values.email}
              onChange={handleChange}
              label={translate("base.your-email")}
              placeholder={translate("base.enter-your-email")}
              error={operations.displayError("email")}
            />

            <Form.PasswordInput
              name="password"
              onBlur={handleBlur}
              value={values.password}
              onChange={handleChange}
              label={translate("base.your-password")}
              placeholder={translate("base.enter-your-password")}
              error={operations.displayError("password")}
            />

            <Form.PasswordInput
              hideButton
              onBlur={handleBlur}
              name="verify_password"
              onChange={handleChange}
              value={values.verify_password}
              label={translate("base.repeat-password")}
              placeholder={translate("base.confirm-password")}
              error={operations.displayError("verify_password")}
            />
          </div>
          <div className="flex flex-col gap-3">
            <AgrementTerms />
            <Button.Pill
              variant="primary"
              className="hover:bg-memod-separator !text-base"
            >
              {translate("base.sign-up.base")}
            </Button.Pill>

            <Button.Pill
              type="button"
              variant="secondary"
              className="hover:bg-memod-black"
              onClick={props.onSignUp}
            >
              {translate("base.social-sign-in")}
            </Button.Pill>
          </div>
        </form>
      </div>

      <div className="w-full bg-memod-black h-14 flex items-center justify-center rounded-b-3xl overflow-hidden">
        <p className="text-memod-white-base text-xs">
          {translate("base.already-account")}{" "}
          <span
            className="text-memod-green-base hover:underline cursor-pointer"
            onClick={props.onSignIn}
          >
            {translate("base.sign-in.base")}
          </span>
        </p>
      </div>
    </div>
  );
}
