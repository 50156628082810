import { useEffect } from "react";
import { motion, Variants } from "framer-motion";

interface Props {
  open?: boolean;
  title: string;
  onClose?: VoidFunction;
}

const variants: Variants = {
  open: {
    opacity: 1,
    display: "flex",
  },
  close: {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  },
};

export default function ErrorMessage(props: Props) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      props.onClose?.();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [props, props.open]);

  return (
    <>
      {props.open && (
        <motion.div
          variants={variants}
          animate={props.open ? "open" : "close"}
          className="absolute top-0 w-full items-center justify-center overflow-hidden rounded-t-3xl bg-[#e54d40] py-3"
        >
          <p className="text-base text-white">{props.title}</p>
        </motion.div>
      )}
    </>
  );
}
