import classNames from "classnames";

interface Props {
  title: string;
  error?: string;
  className?: string;
}

export default function Label(props: Props) {
  return (
    <label className={classNames("text-xs leading-3", props.className)}>
      {`${props.title} `}
      {props.error && <span className="text-memod-error-base">({props.error})</span>}
    </label>
  );
}