import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import { SocialAuthProps } from "..";

interface Props extends Omit<SocialAuthProps, 'type'> {}

export default function SignUp(props: Props){
  return (
    <button type="button" className="sign-in-button bg-memod-green-base text-white" onClick={props.onClick}>
      <div className="divider">
        <Icon.Email />
      </div>

      <span>{translate('base.sign-up.email')}</span>
    </button>
  );
}