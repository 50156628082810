import { atom } from "jotai";

export enum AUTH_STATUS {
  LOGIN,
  SIGN_UP,
  SIGN_UP_FORM,
  SIGN_UP_SECTION,
}

export interface AuthModalProps {
  open: boolean;
  mode: AUTH_STATUS;
}

export const AuthModalAtom = atom<AuthModalProps>({
  open: false,
  mode: AUTH_STATUS.LOGIN,
});
