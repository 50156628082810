import type { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import { useMemo } from "react";

type ButtonType = 'default' | 'secondary' | 'primary';
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "default" | "small";
  variant?: ButtonType;
}

export function usePill(props: Props) {
  const { variant = 'default' } = props;
  const size = useMemo(() => props.size || "default", [props.size]);
  const isDefault = useMemo(() => size === "default", [size]);
  const isSmall = useMemo(() => size === "small", [size]);

  const classname = classNames(
    'transition ease-in-out outline-none select-none rounded-full h-11 disabled:cursor-not-allowed',
    {
      "bg-white text-memod-black hover:bg-white/70": variant === 'default',
      "bg-memod-separator text-white": variant === 'secondary',
      'bg-memod-green-base disabled:bg-memod-green-base/40 text-white disabled:text-white/40 text-sm': variant === 'primary',
    },
    {
      "py-2.5 px-6 font-semibold": isDefault,
      "px-4 py-2 text-sm leading-4": isSmall,
    },
    props.className
  );

  return {
    model: {
      classname,
    },
  };
}

export default function Pill(props: Props) {
  // prevent to pass non valid attributes to button element
  const { size, variant, ...rest } = props;
  const { model } = usePill(props);

  return (
    <button {...rest} className={model.classname}>
      {props.children}
    </button>
  );
}
