import { default as RModal } from "react-modal";
import { PropsWithChildren } from "react";

const customStyles: RModal.Styles = {
  content: {
    background: "transparent",
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    border: "initial",
    padding: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
  },
  overlay: {
    backdropFilter: "blur(6px)",
    background: "rgba(0, 0, 0, 0.2)",
    zIndex: 50,
  },
};

interface Props extends PropsWithChildren {
  open?: boolean;
  onClose?: () => void;
  contentStyle?: RModal.Styles;
}

export default function BaseModal(props: Props) {
  const returnedStyle = props.contentStyle ? ({
    content: Object.assign(customStyles.content || {}, props.contentStyle.content),
    overlay: Object.assign(customStyles.overlay || {}, props.contentStyle.overlay),
  }) : customStyles;

  return (
    <RModal
      isOpen={!!props.open}
      style={returnedStyle}
      shouldCloseOnOverlayClick
    >
      <div className="w-full h-full flex items-center justify-center">
        {props.children}
      </div>
    </RModal>
  );
}
