import { PropsWithChildren, useMemo } from "react";
import { useEffectOnce } from "react-use";
import Cookies from "cookie-universal";
import classNames from "classnames";
import { abTest } from "@models/api/cache";
import { useOnboarding } from "@models/interactions/use-onboarding";
import AuthModal from "../auth-modal";
import { motion } from "framer-motion";
import { useRouter } from "next/router";
import AIBanner from "@components/molecules/ai-banner";

interface Props extends PropsWithChildren {
  Header?: JSX.Element;
  Footer?: JSX.Element;
  className?: string;
  topClassName?: string;
}

const cookies = Cookies();

export default function CommonLayout(props: Props): JSX.Element {
  useOnboarding();

  const route = useRouter();

  useEffectOnce(() => {
    if (!cookies.get("ab-test-variant")) {
      abTest().then((response) => {
        const expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 1);

        cookies.set("ab-test-variant", response.variant, {
          expires: expireDate,
        });
      });
    }
  });

  useEffectOnce(() => {
    // @ts-ignore
    if (!window?.FB) return;

    // @ts-ignore
    window.FB.init({
      appId: process.env.FACEBOOK_APP_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v6.0",
    });
  });

  const key = useMemo(() => route.asPath, [route.asPath]);

  return (
    <div
      className={classNames(
        "min-h-screen w-full bg-memod-base font-gibson",
        props.topClassName
      )}
      style={{ all: "unset" }}
    >
      <AuthModal />
      <AIBanner />

      {props.Header}

      <motion.div
        key={key}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={classNames(
          "flex min-h-screen w-full flex-1 flex-col items-center px-4 pt-16 md:pt-[72px] lg:items-start xl:flex-row xl:px-5",
          props.className
        )}
      >
        {props.children}
      </motion.div>
      {props.Footer}
    </div>
  );
}
