export const invalidate = async (token: string, id: number) =>
  await fetch("/api/invalidate-cache", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token,
      id,
    }),
  }).then((response) => response.json());

export const abTest = async () => {
  const response = await fetch("/api/ab-test", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.json();
}