import Cookies from "cookie-universal";
import { translate } from "@locales";
import useAmplitude from "@models/interactions/use-amplitude";
import Button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import { useEffectOnce } from "usehooks-ts";

interface Props {
  onSignIn?: () => void;
  onSignUp?: () => void;
  onClose?: () => void;
}

const AB_TEST_TEXTS = {
  "variant-a": [
    translate("ab-test.variant-a.bullet-1"),
    translate("ab-test.variant-a.bullet-2"),
    translate("ab-test.variant-a.bullet-3"),
    translate("ab-test.variant-a.bullet-4"),
  ],
  "variant-b": [
    translate("ab-test.variant-b.bullet-1"),
    translate("ab-test.variant-b.bullet-2"),
    translate("ab-test.variant-b.bullet-3"),
    translate("ab-test.variant-b.bullet-4"),
  ],
};

const cookies = Cookies();

export default function SignUpABTest(props: Props) {
  const { operations } = useAmplitude();
  const variant = cookies.get("ab-test-variant");

  useEffectOnce(() => {
    operations.send("SignUp AB Test", {
      variant: variant,
      unknown_user_id: operations.getUserId(),
      opened_at: Date.now(),
    });
  });

  return (
    <div className="bg-memod-base rounded-3xl w-full sm:w-[500px] flex flex-col justify-between text-white">
      <div className="flex flex-col">
        <header className="p-8 flex flex-col">
          <Icon.Close className="text-white self-end cursor-pointer" onClick={props.onClose} />
          <div className="flex justify-center mt-4">
            <Icon.MiniMemod />
          </div>
        </header>

        <div className="flex flex-col gap-3.5 w-full px-[4.5rem] mb-2">
          <h2 className="font-ZillaSlab font-semibold italic text-[32px] leading-[32px] text-center mb-12">
            {translate("ab-test.title")}
          </h2>
          <div className="flex flex-col text-xl gap-4 mb-10">
            {AB_TEST_TEXTS[variant as keyof typeof AB_TEST_TEXTS].map((bullet, index) => (
              <div key={`bullet-${index}`} className="flex items-center gap-4">
                <Icon.VerifyUser size={24} strokeColor="#000000" fillColor="#FFFFFF" />
                <p>{bullet}</p>
              </div>
            ))}
          </div>
          <Button.Pill variant="primary" className="!text-xl !h-auto w-full" onClick={props.onSignUp}>
            {translate("base.sign-up.base")}
          </Button.Pill>
        </div>
      </div>

      <div className="w-full bg-memod-black h-12 flex items-center justify-center rounded-b-3xl overflow-hidden shrink-0">
        <p className="text-memod-w@chanhite-base text-xl">
          {translate("base.already-account")}{" "}
          <span
            className="text-memod-green-base hover:underline cursor-pointer"
            onClick={props.onSignIn}
          >
            {translate("base.sign-in.base")}
          </span>
        </p>
      </div>
    </div>
  );
}