import { ChangeEvent, FocusEvent } from "react";
import PasswordInput from "./password-input";
import TextInput from "./text-input";

export interface InputProps {
  label?: string;
  value: string | number;
  name: string;
  error?: string;
  placeholder?: string;
  readonly?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

export default {
  TextInput,
  PasswordInput,
}