import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";

interface Props {
  title: string;
  links: any;
}

export default function DropDown(props: Props) {
  const { title, links } = props;

  return (
    <Menu>
      <Menu.Button>{title}</Menu.Button>
      <Menu.Items>
        {links.map((link: any) => (
          /* Use the `active` state to conditionally style the active item. */
          <Menu.Item key={link.label} as={Fragment}>
            {({ active }) => (
              <button
                onClick={link.onClick}
                className={classNames(
                  "flex !h-[54px] !w-[155px] flex-row bg-white text-black",
                  { "bg-blue-500 text-white": active }
                )}
              >
                {link.label}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}
