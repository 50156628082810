import { useAtomValue } from "jotai";
import { UserAtom } from "@models/state";
import { useRouter } from "next/router";
import Cookies from "cookie-universal";

const cookies = Cookies();

export function useOnboarding() {
  const router = useRouter();
  const user = useAtomValue(UserAtom);
  const inOnboarding = router.pathname.startsWith("/onboarding");
  const inCollection = router.pathname.startsWith("/[user]/collections/[...slug]");

  if (user) {
    if (!user.welcome && !inOnboarding) {
      if (inCollection) {
        cookies.set("collection-redirect", router.asPath);
      }
      router.push("/onboarding");
    } else if (user.welcome && inOnboarding) {
      router.push("/home/following");
    }
  }
}
